<template>
  <div class="container page">
    <van-nav-bar :title="$t('提现中心')" class="nav-bar">
      <template #left>
        <van-icon name="arrow-left" color="#fff" @click="back()"/>
      </template>
      <template #right>
        <span class="nav-right" @click="$router.push({path:'/WithdrawRecord'})">{{$t('提现记录')}}</span>
      </template>
    </van-nav-bar>
    <div class="main">
      <div class="withdrawMoney">
        <span>{{$t('提现金额 (元)')}}</span>
        <div class="money">
          <div class="moneyNumber">
            <span class="moneyType">{{$t('元')}}</span>
            <van-field v-model="withdraw_money" type="number" />
          </div>
          <span class="all" @click="allMoeny()">{{$t('全部')}}</span>
        </div>
        <div class="information">
          <div class="description">
            <van-popover v-model="showPopover" trigger="click">
              <div class="popover-body" style="padding: 10px;">
                <p>1.{{$t('单笔限额：最低')}}{{this.withdrawRole.min}}{{$t('元')}}，{{$t('最高')}}{{this.withdrawRole.max}}{{$t('元')}}</p>
                <p>2.{{$t('提现次数：一天最高提现')}}{{this.withdrawRole.num}}{{$t('次')}}</p>
                <p>3.{{$t('到账时间：一般到账时间在5分钟左右，最快2分钟内到账')}}</p></div>
              <template #reference @click="withdrawInfo()">
                <van-icon name="info-o" />
                {{$t('限额说明')}}
              </template>
            </van-popover>
          </div>

          <div class="balance">
            <span>{{$t('余额')}}：</span>
            <span class="number">{{ this.userInfo.money }}{{$t('元')}}</span>
          </div>
        </div>
			<div class="money" style="border-top: 1px solid #ccc; padding: 20px 0px; font-size: 0.9rem;">
				<div class="moneyNumber">
					<span class="shoukuanqudao">{{$t('收款渠道')}}：</span>
					<div>
						<span class="bank_item" :class="{'on': item.type==bank_type}" @click="selectBank(item.type)" v-for="(item,index) in bank_list" :key="index">{{item.type==1 ? $t("银行卡"): (item.type==2 ? $t("數快"):"USDT")}}</span>
					</div>
				</div>
			</div>
      </div>
      <van-button class="withdraw_btn" type="default" @click="doWithdraw()">{{$t('马上提现')}}</van-button>

    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
		showPopover: false,
		withdraw_money:"",
		bank_type: 0,
		userInfo:{},
		withdrawRole:{},
		bank_list:[{},{},{}],
    };
  },
  methods: {
    back(){
      return window.history.back();
    },
	selectBank(type){
		this.bank_type = type;
	},
    getUserInfo(){
      this.$http({
        method: 'get',
        url: 'user_info'
      }).then(res=>{
        if(res.code === 200){
          this.userInfo = res.data;
          this.name = res.data.name;
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    },
    getUserWithdrawRole(){
      this.$http({
        method: 'get',
        url: 'user_get_withdraw_role'
      }).then(res=>{
        if(res.code === 200){
          this.withdrawRole = res.data;
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    },
	getUserBankList(){
	this.$http({
	method: 'get',
	url: 'user_get_bank_list'
	}).then(res=>{
	if(res.code === 200){
	console.log("res.data", res.data)
	this.bank_list = res.data.infoList;
	this.bank_type = res.data.infoList[0].type;
	}else if(res.code ===401){
	this.$toast(res.msg);
	}
	})
	},
    allMoeny(){
      this.withdraw_money = this.userInfo.money;
    },
    doWithdraw(){
      if(this.withdraw_money <= 0){
        this.$toast(this.$t("请填写正确的金额"));
        return false;
      }else {
        this.$http({
          method: 'post',
          data:{money:this.withdraw_money, bk_id:this.bank_type},
          url: 'user_set_withdraw'
        }).then(res=>{
          if(res.code === 200){
            location. reload()
            this.$toast(this.$t('保存成功'));
            this.getUserInfo();
            this.getUserWithdrawRole();
          }else if(res.code ===401){
            this.$toast(this.$t(res.msg));
          }
        })
      }
    },
    withdrawInfo(){
      this.showPopover = true;
    }
  },
  created() {
    if(!localStorage.getItem('token')){
      this.$router.push({path:'/Login'})
    }else {
		this.getUserInfo();
		this.getUserWithdrawRole();
		this.getUserBankList();
    }
  }
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";
.shoukuanqudao{
	font-size: 0.9rem;
}
.on{
	border: 1px solid red !important;
	color: red;
}
.bank_item{
	font-size: 0.9rem;
	padding: 5px 15px !important;
	border: 1px solid #333;
	text-align: center;
	margin-right: 10px;
}
.van-cell {
  font-size: 35px;
  line-height: 80px;
}
.container p{
  padding: 0 15px;
  margin-top: 15px;
  font-size: 30px;
  color: #dc2037;
}
.container .main{
  display: flex;
  flex-direction: column;
  background-color: #f2f2f5;
  height: calc(100% - 50px);
  position: relative;
}
.container .main .withdrawMoney{
  display: flex;
  flex-direction: column;
  color: #000;
  padding: 0 20px;
  white-space: nowrap;
  font-size: 35px;
  background-color: #fff;
}
.container .main .withdrawMoney span {
  padding: 10px 0;
}
.container .main .withdrawMoney .money{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #f2f2f5;
}
.container .main .withdrawMoney .money .moneyNumber{
  font-size: 50px;
  display: flex;
  flex-direction: row;
}
.container .main .withdrawMoney span {
  padding: 10px 0;
}
.container .main .withdrawMoney .money .all{
  color: #d10404;
}
.container .main .withdrawMoney .money .moneyNumber .van-cell {
  font-size: 50px;
  padding: 0!important;
}
.container .main .withdrawMoney .information{
  padding-bottom: 30px;
}
.container .main .withdrawMoney .information .description {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px 0;
}
.container .main .withdrawMoney span{
  padding: 10px 0;
}
.container .main .withdrawMoney .information .balance .number{
  color: #d10404;
}
.withdraw_btn {
  margin: 20px 30px 0;
  height: 80px;
  line-height: 1.22667rem;
  border-radius: 50px;
  color: #fff;
  font-size: 30px;
  font-weight: bolder;
  border: none;
  background: linear-gradient(
      90deg,#e6c3a1,#7e5678);
}
</style>
